//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-816:_3524,_3776,_2540,_4802,_1220,_5000,_7848,_6800;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-816')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-816', "_3524,_3776,_2540,_4802,_1220,_5000,_7848,_6800");
        }
      }catch (ex) {
        console.error(ex);
      }